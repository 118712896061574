import type { SizeLocales } from "@lib/constants/filtersConstants";
import { shoeSizingConventions, localizationConfig } from "./localizationConfigObject";
export interface LocalizationConfigEntry {
    country: string;
    countryName: string;
    language: string;
    currency: string;
    fractionDigits: number;
    shoeSizingConventions?: SizeLocales[];
    checkout: {
        countryCode: string;
    };
    geo: {
        centerLat: number;
        centerLng: number;
        swLat: number;
        swLng: number;
        neLat: number;
        neLng: number;
        initZoomLevel?: number;
    };
    orderNumberFormatRegex: RegExp;
    reCaptchaIntegration: {
        contactForm: boolean;
        checkout: boolean;
    };
}

const getHostnameLocaleConfig = <Config>(): Config => {
    if (!process.env.NEXT_PUBLIC_HOSTNAME) {
        throw new Error(`Missing env variable HOSTNAME and NEXT_PUBLIC_HOSTNAME`);
    }

    const hostNameConfig = localizationConfig[process.env.NEXT_PUBLIC_HOSTNAME];
    if (!hostNameConfig) {
        throw new Error(`No Config found for hostname ${process.env.NEXT_PUBLIC_HOSTNAME}`);
    }
    return hostNameConfig;
};

export const getLocaleConfig = () => getHostnameLocaleConfig<LocalizationConfigEntry>();

export const gottenConfig = getLocaleConfig();

export const getCurrencyForSite = gottenConfig?.currency;

export const getCountryForSite = gottenConfig?.country;

export const getCountryCode = gottenConfig?.checkout.countryCode;

export const getOrderNumberFormatRegex = gottenConfig?.orderNumberFormatRegex;

export const getLanguageForLocale = (locale: string) => {
    if (locale && locale.includes("-")) {
        return locale.split("-")[0];
    }
    return gottenConfig?.language;
};

export const getCheckoutForSite = gottenConfig?.checkout;

export const shoeConventionForSite = (gottenConfig?.shoeSizingConventions ||
    shoeSizingConventions) as SizeLocales[];

export const getFractionDigitsForLocale = () => gottenConfig?.fractionDigits;

export const getHostnameForCountry = (countryCode: string): string | undefined => {
    const entry = Object.entries(localizationConfig).find(
        ([_, config]) => config.country === countryCode
    );
    return entry ? entry[0] : undefined;
};

export const reCaptchaIntegrationEnabled =
    gottenConfig.reCaptchaIntegration.checkout || gottenConfig.reCaptchaIntegration.contactForm;

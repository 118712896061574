import type { RawCustomField, RawProductAttribute } from "@graphql/generated/components";
import type { CustomAttributes } from "@lib/enums/ProductAttributes";
import { Attributes } from "@lib/enums/ProductAttributes";
import hexRgb from "hex-rgb";

export const getProductAttributeValue = (
    attributes: RawProductAttribute[] | RawCustomField[],
    name: Attributes | CustomAttributes,
    locale?: string
): any => {
    const value = (attributes as unknown as any[])?.find((attr) => attr.name === name)?.value || {};

    return locale ? value[locale] : value;
};

export const getBackgroundColorValue = (attributes: RawProductAttribute[]) => {
    try {
        const backgroundPropertyValue = getProductAttributeValue(
            attributes,
            Attributes.backgroundColor
        );
        return hexRgb(backgroundPropertyValue, { format: "css", alpha: 1 });
    } catch {
        return hexRgb("#FFF", { format: "css" });
    }
};

export const getColorObject = (mapping: Map<any, string>, colorName: string, language: string) => {
    return Array.from(mapping.keys()).find(
        (obj) => Object.values(obj).includes(colorName) && obj.hasOwnProperty(language)
    );
};

export const getEnrichedPageTitle = (
    rawAttributes: RawProductAttribute[],
    itemName: string,
    locale: string,
    separator: string
) => {
    const dominantColor = getProductAttributeValue(rawAttributes, Attributes.dominantColor, locale);
    const pageTitle = getProductAttributeValue(rawAttributes, Attributes.pageTitle, locale);

    return `${pageTitle ?? itemName} ${separator} ${dominantColor}`;
};

export const getImageAltTag = (altTagPattern: string, imageLabel: string) => {
    if (!imageLabel) return altTagPattern;
    const imageAlt = imageLabel.charAt(0).toUpperCase() + imageLabel.slice(1);
    return `${altTagPattern} - ${imageAlt}`;
};

import { getSession, useSession } from "next-auth/react";
import { useEffect, useState } from "react";

// A wrapper around next-auth getSession
export async function getAuth() {
    const session = await getSession();
    const isAuthenticated = typeof session !== "undefined" && session !== null;
    const isAnonymous = session?.anonymousId && isAuthenticated;
    const hasSession = isAuthenticated || isAnonymous;
    return {
        session,
        isAuthenticated,
        isAnonymous: session?.anonymousId && isAuthenticated,
        hasSession,
    };
}

// A wrapper around next-auth useSession
export function useAuth() {
    const { data: session } = useSession();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAnonymous, setIsAnonymous] = useState(false);
    const [hasSession, setHasSession] = useState(false);

    useEffect(() => {
        const nextIsAuthenticated =
            typeof session !== "undefined" && session !== null && !session?.anonymousId;
        const nextIsAnonymous =
            typeof session?.anonymousId !== "undefined" && session?.anonymousId !== null;
        setIsAuthenticated(nextIsAuthenticated);
        setIsAnonymous(nextIsAnonymous);
        setHasSession(nextIsAuthenticated || nextIsAnonymous);
    }, [session]);

    return {
        session,
        isAuthenticated,
        isAnonymous,
        hasSession,
    };
}

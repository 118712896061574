import { datadogLogs } from "@datadog/browser-logs";
import type { PageTypes } from "@lib/enums/PageTypes";
import { hasWindow } from "./../utils/hasWindow";

enum LogType {
    INFO = "info",
    WARN = "warn",
    ERROR = "error",
}

export enum ServiceType {
    CONTENTFUL = "Contentful",
    COMMERCE_TOOLS = "CommerceTools",
    NEXT_API = "NextJS Api",
    ALGOLIA = "Algolia",
    WEB = "Web",
    ADYEN = "Adyen",
}

export enum LogTag {
    PAYMENT = "PAYMENT",
    MICROCOPY = "MICROCOPY",
    PERFORMANCE = "PERFORMANCE",
    ERROR = "ERROR",
    SUCCESS = "SUCCESS",
    MISSING_ADDRESS_FIELDS = "MISSING_ADDRESS_FIELDS",
    STORE_DATA = "STORE_DATA",
}

type LogPayload = {
    [key: string]: any;
    tag: LogTag;
    _duration?: number;
    _pageType?: PageTypes;
};

const consoleLogFunctions = {
    [LogType.ERROR]: console.error,
    [LogType.WARN]: console.warn,
    [LogType.INFO]: console.log,
};

export abstract class Logger {
    private static log(
        logType: LogType,
        serviceType: ServiceType,
        message: string,
        payload: LogPayload
    ) {
        const { tag, ...restPayload } = payload;
        const logPayload = {
            _logger: "ECCO",
            _tag: payload.tag,
            _level: logType,
            _service: serviceType,
            _message: message,
            _caller: hasWindow ? "client" : "server",
            _data: restPayload,
        };

        if (hasWindow) {
            datadogLogs.logger[logType](message, logPayload);
        } else {
            const stringifiedPayload = JSON.stringify(logPayload);

            consoleLogFunctions[logType](stringifiedPayload);
        }
    }

    static info(serviceType: ServiceType, message: string, payload: LogPayload) {
        this.log(LogType.INFO, serviceType, message, payload);
    }

    static warn(serviceType: ServiceType, message: string, payload: LogPayload) {
        this.log(LogType.WARN, serviceType, message, payload);
    }

    static error(serviceType: ServiceType, message: string, payload: LogPayload) {
        this.log(LogType.ERROR, serviceType, message, payload);
    }
}

import { getCurrencyForSite, getLanguageForLocale } from "@config/site/site-config";
import {
    PaymentState,
    type LineItem,
    type Money,
    type Order,
    type Product,
    type ProductVariant,
} from "@graphql/generated/components";
import { CLICK_OBJECT_AFTER_SEARCH, CLICK_OBJECT_WITHOUT_SEARCH } from "@lib/analytics/constants";
import { getImperialSizeForProduct } from "@lib/analytics/getImperialSizeForProduct";
import { sha256 } from "@lib/analytics/sha256";
import { Attributes, CustomAttributes } from "@lib/enums/ProductAttributes";
import { getProductAttributeValue } from "@lib/utils/attributesUtils";
import { getMoneyFromCentsAndFractions } from "@lib/utils/moneyUtils";
import type { Me } from "@store/index";
import type { Hit } from "instantsearch.js";
import { isEqual } from "lodash-es";
import type { Session } from "next-auth";

class AnalyticsClient {
    locale: string;
    session: Session;
    me: Me;
    algoliaIndexName: string = "";
    lastSavedEvents: Map<EventTypes | AlgoliaEventTypes, any> = new Map();
    lastVisitedUrl: string = "";
    selectedItems: Map<
        string,
        ListData & {
            lastAppliedFilter: { filterName: string; filterCategory: string };
            lastAppliedSorting?: { sorting: string };
        }
    > = new Map();
    lastAppliedSorting: { sorting: string };
    lastAppliedFilter: { filterName: string; filterCategory: string };
    lastAppliedSearch: string;
    lastSelectedPromotion: {
        promotionName: string;
        promotionId: string;
    } = {
        promotionName: undefined,
        promotionId: undefined,
    };
    currencyCode: string;

    saveEvent(
        event: { event: EventTypes | AlgoliaEventTypes; [key: string]: any },
        shouldCheckIfSaved = true
    ) {
        if (shouldCheckIfSaved && this.isAlreadyPushed(event.event, event)) {
            return;
        }
        this.savePushedEvent(event.event, event);
        window.dataLayer && window.dataLayer.push(event);
    }

    saveEcommerceEvent(event: { event: EventTypes | AlgoliaEventTypes; [key: string]: any }) {
        if (this.isAlreadyPushed(event.event, event)) {
            return;
        }

        this.savePushedEvent(event.event, event);
        window.dataLayer &&
            window.dataLayer.push({ ecommerce: null }) &&
            window.dataLayer.push(event);
    }
    getMoneyFromItem = (item: PossibleEventItem) => {
        const isProduct = (it: PossibleEventItem): it is Product => {
            return (it as ProductVariant).price === undefined;
        };

        if (!isProduct(item)) {
            return getMoneyFromCentsAndFractions(
                item.price?.value?.centAmount ?? 0,
                item.price?.value?.fractionDigits ?? 0
            );
        } else {
            return getMoneyFromCentsAndFractions(
                item.masterData?.current?.variants.at(-1)?.price?.value?.centAmount ?? 0,
                item.masterData?.current?.variants.at(-1)?.price?.value?.fractionDigits ?? 0
            );
        }
    };

    getCartValue = (items) => {
        return items.reduce((acc, item) => acc + this.getMoneyFromItem(item) * item.quantity, 0);
    };

    mapLineItemToEventItem = (item: LineItem, index?: number): AnalyticsExtendedItem => {
        const params = new URLSearchParams(window.location.href);
        const savedListingData = this.selectedItems.get(item.key);

        const lineItemPrice: Money = getProductAttributeValue(
            item.custom.customFieldsRaw,
            CustomAttributes.lineItemPrice
        );
        const lineItemDiscounted: Money = getProductAttributeValue(
            item.custom.customFieldsRaw,
            CustomAttributes.lineItemDiscountedPrice
        );

        return {
            item_id: item.id,
            item_variant: item.productKey,
            item_name: item.name,
            promotion_name: this.lastSelectedPromotion.promotionName,
            promotion_id: this.lastSelectedPromotion.promotionId,
            item_list_name: savedListingData?.listName,
            item_list_id: savedListingData?.listId,
            item_category: getProductAttributeValue(
                item.variant?.attributesRaw,
                Attributes.division,
                this.locale
            ),
            item_category2: getProductAttributeValue(
                item.variant?.attributesRaw,
                Attributes.collectionType,
                this.locale
            ),
            item_category3: getProductAttributeValue(
                item.variant?.attributesRaw,
                Attributes.collectionClass,
                this.locale
            ),
            item_brand: "ECCO",
            currency: this.currencyCode,
            price: getMoneyFromCentsAndFractions(
                item.totalPrice?.centAmount ?? 0,
                item.totalPrice?.fractionDigits ?? 0
            ),
            quantity: item.quantity,
            index: index ?? savedListingData?.position ?? 0,
            discount:
                lineItemDiscounted?.centAmount && lineItemPrice?.centAmount
                    ? getMoneyFromCentsAndFractions(
                          lineItemPrice.centAmount - lineItemDiscounted.centAmount,
                          lineItemPrice.fractionDigits
                      )
                    : 0,
            coupon: item.discountedPricePerQuantity[0]?.discountedPrice.includedDiscounts[0]
                ?.discount.key,
            list_sorting: savedListingData?.lastAppliedSorting?.sorting,
            list_filter_category: savedListingData?.lastAppliedFilter?.filterCategory,
            list_filter_name: savedListingData?.lastAppliedFilter?.filterName,
            list_search_key: this.lastAppliedSearch,
            colour: getProductAttributeValue(
                item.variant?.attributesRaw,
                Attributes.dominantColor,
                this.locale
            ),
            variant_price: this.getMoneyFromItem(item),
            full_price: lineItemPrice
                ? getMoneyFromCentsAndFractions(
                      lineItemPrice.centAmount,
                      lineItemPrice.fractionDigits
                  )
                : 0,
            default_variant: item.productKey,
            size: getProductAttributeValue(item.variant?.attributesRaw, Attributes.size),
            size_imperial: getImperialSizeForProduct(item),
            size_type: params.get("system") === "us" ? "Imperial" : "Metric",
            barcode: item.variant?.sku,
            stock_count_online: item.variant?.availability?.channels?.total ?? 0,
            stock_count_offline: undefined,
            on_sale: undefined,
            label_name: undefined,
            label_source: undefined,
            source: undefined,
        };
    };

    saveSearch = (searchTerm) => {
        this.lastAppliedSearch = searchTerm;
    };

    mapCommercetoolsItemToEventItem = (
        item: Product & { variant?: Partial<ProductVariant> }
    ): AnalyticDefaultItem | AnalyticsExtendedItem => {
        const savedListingData = this.selectedItems.get(item.key);
        const params = new URLSearchParams(window.location.href);

        return {
            item_id: item.id,
            item_variant: item.key,
            item_name: item.masterData?.current?.name,
            promotion_name: this.lastSelectedPromotion.promotionName,
            promotion_id: this.lastSelectedPromotion.promotionId,
            colour: getProductAttributeValue(
                item.masterData?.current?.masterVariant?.attributesRaw,
                Attributes.dominantColor,
                this.locale
            ),
            item_list_name: savedListingData?.listName,
            item_list_id: savedListingData?.listId,
            item_category: getProductAttributeValue(
                item.masterData?.current?.masterVariant?.attributesRaw,
                Attributes.division,
                this.locale
            ),
            item_category2: getProductAttributeValue(
                item.masterData?.current?.masterVariant?.attributesRaw,
                Attributes.collectionType,
                this.locale
            ),
            item_category3: getProductAttributeValue(
                item.masterData?.current?.masterVariant?.attributesRaw,
                Attributes.collectionClass,
                this.locale
            ),
            item_brand: "ECCO",
            currency: this.currencyCode,
            price: this.getMoneyFromItem(item),
            quantity: 1,
            index: savedListingData?.position ?? 0,
            discount: 0,
            coupon: "",
            default_variant: item.key,
            list_sorting: savedListingData?.lastAppliedSorting?.sorting,
            list_filter_category: savedListingData?.lastAppliedFilter?.filterCategory,
            list_filter_name: savedListingData?.lastAppliedFilter?.filterName,
            list_search_key: this.lastAppliedSearch,
            size: getProductAttributeValue(item?.variant?.attributesRaw, Attributes.size) ?? "",
            size_imperial: getImperialSizeForProduct(item),
            size_type: params.get("system") === "us" ? "Imperial" : "Metric",
            barcode: item.variant?.sku,
        };
    };

    mapAlgoliaItemToEventItem = (
        item: Hit<AlgoliaItem>,
        listData?: ListData
    ): AnalyticDefaultItem => {
        const { listName = "", listId = "loaded", position = 0 } = listData;

        return {
            item_id: item.objectID,
            item_variant: item.articleNumber,
            item_name: item.name,
            promotion_name: this.lastSelectedPromotion.promotionName,
            promotion_id: this.lastSelectedPromotion.promotionId,
            item_list_name: listName,
            item_list_id: listId,
            item_category: item.division ?? "",
            item_category2: item.collectionType ?? "",
            item_category3: item.collectionClass,
            item_brand: "ECCO",
            currency: this.currencyCode,
            price: item.price,
            quantity: 1,
            index: position,
            discount: item.hasDiscount ? item.discountPercentage : 0,
            coupon: "",
            list_sorting: this.lastAppliedSorting?.sorting,
            list_filter_category: this.lastAppliedFilter?.filterCategory,
            list_filter_name: this.lastAppliedFilter?.filterName,
            list_search_key: this.lastAppliedSearch,
            barcode: item.objectID,
        };
    };

    init(locale: string, me: Me, session: Session) {
        this.me = me;
        this.locale = locale;
        this.currencyCode = getCurrencyForSite;
        this.session = session;
        this.algoliaIndexName = process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME;
        //@ts-ignore
        window.analyticsClient = this;
    }

    savePushedEvent(eventType: EventTypes | AlgoliaEventTypes, item: any) {
        const savedData = this.lastSavedEvents.get(eventType) ?? [];
        this.lastSavedEvents.set(eventType, [...savedData, item]);
    }

    isAlreadyPushed(eventType: EventTypes | AlgoliaEventTypes, item: any) {
        const lastSavedEvents = this.lastSavedEvents.get(eventType) ?? [];
        return lastSavedEvents.find((it) =>
            isEqual(
                { ...item, "gtm.uniqueEventId": undefined },
                {
                    ...it,
                    "gtm.uniqueEventId": undefined,
                }
            )
        );
    }

    pageView = (url, pageType) => {
        this.saveEvent({
            event: EventTypes.pageView,
            new_url: url,
            previous_url: this.lastVisitedUrl,
            content_group: pageType,
        });
        this.lastVisitedUrl = url;
    };

    updateAnalyticsClientSettings(me: Me, session: Session) {
        this.me = me;
        this.session = session;
    }

    viewItem = async (item: Product, algoliaQueryID?: string) => {
        if (!item?.masterData) {
            return;
        }

        try {
            this.saveEcommerceEvent({
                event: EventTypes.viewItem,
                ecommerce: {
                    value: this.getMoneyFromItem(item),
                    event_id: await sha256(Math.random + Date.now().toString()),
                    items: [this.mapCommercetoolsItemToEventItem(item)],
                },
            });
            if (!algoliaQueryID) {
                this.saveEvent({
                    event: AlgoliaEventTypes.pdpNavigatedWithoutSearch,
                    objectIDs: [item.key],
                    methodName: CLICK_OBJECT_WITHOUT_SEARCH,
                    indexName: this.algoliaIndexName,
                    userToken:
                        this.me?.cartInfo?.cart?.anonymousId ??
                        this.me?.cartInfo?.cart?.id ??
                        "unknown_user",
                });
            }
        } catch (e) {
            console.warn("AnalyticsClient.viewItem error", e);
        }
    };

    selectItem(item: AlgoliaItem & { position?: number }, listData: ListData, queryID = "") {
        if (!item.objectID) {
            return;
        }
        try {
            const position = item.position ? item.position : listData.position + 1;
            this.selectedItems.set(item.objectID, {
                ...listData,
                listId: "clicked",
                position,
                lastAppliedFilter:
                    listData.listName === AnalyticsListTypes.plp
                        ? this.lastAppliedFilter
                        : undefined,
                lastAppliedSorting:
                    listData.listName === AnalyticsListTypes.plp
                        ? this.lastAppliedSorting
                        : undefined,
            });
            this.saveEcommerceEvent({
                event: EventTypes.selectItem,
                ecommerce: {
                    value: item.price,
                    items: [
                        this.mapAlgoliaItemToEventItem(
                            { ...item, __position: position, __queryID: queryID },
                            { ...listData, listId: "clicked" }
                        ),
                    ],
                },
            });

            this.saveEvent({
                event: AlgoliaEventTypes.pdpNavigatedAfterSearch,
                objectIDs: [item.objectID],
                methodName: CLICK_OBJECT_AFTER_SEARCH,
                queryID,
                position,
                indexName: this.algoliaIndexName,
                userToken:
                    this.me?.cartInfo?.cart?.anonymousId ??
                    this.me?.cartInfo?.cart?.id ??
                    "unknown_user",
            });
        } catch (e) {
            console.warn("AnalyticsClient.selectItem error");
        }
    }

    loadItemsList(items: AlgoliaItem[], listData: ListData = {}) {
        if (!items || !items.length) {
            return;
        }
        try {
            const otherItems = [...items];
            while (otherItems.length) {
                const slicedItems = otherItems.splice(0, 15);
                this.saveEcommerceEvent({
                    event: EventTypes.loadItemsList,
                    ecommerce: {
                        value: slicedItems.reduce((acc, it) => acc + it.price, 0),
                        items: slicedItems.map((it: Hit<AlgoliaItem>, index) =>
                            this.mapAlgoliaItemToEventItem(it, {
                                ...listData,
                                listId: "loaded",
                                position: index,
                            })
                        ),
                    },
                });
            }
        } catch (e) {
            console.warn("AnalyticsClient.loadItemsList error", e);
        }
    }

    viewItemsList(items: AlgoliaItem[], listData: ListData) {
        if (!items || !items.length) {
            return;
        }

        try {
            const otherItems = [...items];
            while (otherItems.length) {
                const slicedItems = otherItems.splice(0, 15);

                this.saveEcommerceEvent({
                    event: EventTypes.viewItemsList,
                    ecommerce: {
                        value: slicedItems.reduce((acc, it) => acc + it.price, 0),
                        items: slicedItems.map((it: Hit<AlgoliaItem>, index) =>
                            this.mapAlgoliaItemToEventItem(it, {
                                ...listData,
                                listId: "viewed",
                                position: index,
                                ...this.lastAppliedFilter,
                                ...this.lastAppliedSorting,
                            })
                        ),
                    },
                });
            }
        } catch (e) {
            console.warn("AnalyticsClient.viewItemsList error");
        }
    }

    addToCart = async (item: PossibleEventItem, algoliaQueryID?: string | string[]) => {
        if (!item) {
            return;
        }

        const isProduct = (it: PossibleEventItem): it is Product => {
            return (it as Product).masterData !== undefined;
        };

        try {
            this.saveEcommerceEvent({
                event: EventTypes.addToCart,
                ecommerce: {
                    value: this.getMoneyFromItem(item),
                    event_id: await sha256(
                        EventTypes.addToCart + Math.random + Date.now().toString()
                    ),
                    items: [
                        isProduct(item)
                            ? this.mapCommercetoolsItemToEventItem(item as Product)
                            : this.mapLineItemToEventItem(item as LineItem),
                    ],
                },
            });
            this.saveEvent({
                event: algoliaQueryID
                    ? AlgoliaEventTypes.pdpProductAddedAfterSearch
                    : AlgoliaEventTypes.pdpProductAddedToCart,
                indexName: this.algoliaIndexName,
                objectIDs: [item.key],
                queryID: algoliaQueryID,
                methodName: algoliaQueryID
                    ? CLICK_OBJECT_AFTER_SEARCH
                    : CLICK_OBJECT_WITHOUT_SEARCH,
                userToken:
                    this.me?.cartInfo?.cart?.anonymousId ??
                    this.me?.cartInfo?.cart?.id ??
                    "unknown_user",
            });
        } catch (e) {
            console.warn("AnalyticsClient.addToCart error", e);
        }
    };

    addToCartFromShoppingBag = async (item: LineItem) => {
        if (!item) {
            return;
        }
        try {
            this.saveEcommerceEvent({
                event: EventTypes.addToCart,
                value: this.getMoneyFromItem(item),
                event_id: await sha256(EventTypes.addToCart + Math.random + Date.now().toString()),
                items: [this.mapLineItemToEventItem(item)],
            });
        } catch (e) {
            console.warn("AnalyticsClient.addToCart error", e);
        }
    };

    addToWishList = async (item: Hit<AlgoliaItem>, listData = {}) => {
        if (!item) {
            return;
        }
        try {
            this.saveEcommerceEvent({
                event: EventTypes.addToWishlist,
                ecommerce: {
                    value: item.price,
                    event_id: await sha256(
                        EventTypes.addToWishlist + Math.random + Date.now().toString()
                    ),
                    items: [
                        this.mapAlgoliaItemToEventItem(item, { ...listData, listId: "clicked" }),
                    ],
                },
            });
        } catch (e) {
            console.warn("AnalyticsClient.addToWishList error", e);
        }
    };

    removeFromWishList = async (item: Hit<AlgoliaItem>, listData = {}) => {
        if (!item) {
            return;
        }
        try {
            this.saveEcommerceEvent({
                event: EventTypes.removeFromWishlist,
                ecommerce: {
                    value: item.price,
                    event_id: await sha256(
                        EventTypes.removeFromWishlist + Math.random + Date.now().toString()
                    ),
                    items: [
                        this.mapAlgoliaItemToEventItem(item, { ...listData, listId: "clicked" }),
                    ],
                },
            });
        } catch (e) {
            console.warn("AnalyticsClient.removeFromWishList error", e);
        }
    };

    addCTProductToWishList = async (item: Product & { variant?: Partial<ProductVariant> }) => {
        if (!item) {
            return;
        }
        try {
            this.saveEcommerceEvent({
                event: EventTypes.addToWishlist,
                ecommerce: {
                    value: this.getMoneyFromItem(item),
                    event_id: await sha256(
                        EventTypes.addToWishlist + Math.random + Date.now().toString()
                    ),
                    items: [this.mapCommercetoolsItemToEventItem(item)],
                },
            });
        } catch (e) {
            console.warn("AnalyticsClient.addToWishListFromPDP error", e);
        }
    };

    removeCTProductFromWishlist = async (item: LineItem) => {
        if (!item) {
            return;
        }

        try {
            this.saveEcommerceEvent({
                event: EventTypes.removeFromWishlist,
                ecommerce: {
                    value: this.getMoneyFromItem(item),
                    event_id: await sha256(
                        EventTypes.removeFromWishlist + Math.random + Date.now().toString()
                    ),
                    items: [this.mapLineItemToEventItem(item)],
                },
            });
        } catch (e) {
            console.warn("AnalyticsClient.removeFromWishlist error", e);
        }
    };

    removeFromCart(item: LineItem, index) {
        if (!item) {
            return;
        }
        try {
            this.saveEcommerceEvent({
                event: EventTypes.removeFromCart,
                ecommerce: {
                    value: this.getMoneyFromItem(item),
                    items: [this.mapLineItemToEventItem(item, index)],
                },
            });
        } catch (e) {
            console.warn("AnalyticsClient.removeFromCart error");
        }
    }

    viewCart = async (items) => {
        if (!items) {
            return;
        }

        try {
            this.saveEcommerceEvent({
                event: EventTypes.viewCart,
                ecommerce: {
                    value: this.getCartValue(items),
                    event_id: await sha256(
                        EventTypes.viewCart + Math.random + Date.now().toString()
                    ),
                    items: items.map(this.mapLineItemToEventItem),
                },
            });
        } catch (e) {
            console.warn("AnalyticsClient.viewCart error");
        }
    };

    beginCheckout = async (items) => {
        if (!items) {
            return;
        }

        try {
            const mappedItems = items.map(this.mapLineItemToEventItem);
            this.saveEcommerceEvent({
                event: EventTypes.beginCheckout,
                ecommerce: {
                    value: this.getCartValue(items),
                    event_id: await sha256(
                        EventTypes.beginCheckout + Math.random + Date.now().toString()
                    ),
                    coupon: this?.me?.cartInfo?.cart?.discountCodes
                        .map((it) => it.discountCode.code)
                        .join(", "),
                    items: mappedItems,
                },
            });
        } catch (e) {
            console.warn("AnalyticsClient.beginCheckout error");
        }
    };

    addShippingInfo(items: LineItem[], shippingMethod?: string) {
        if (!items) {
            return;
        }

        try {
            this.saveEcommerceEvent({
                event: EventTypes.addShippingInfo,
                ecommerce: {
                    value: this.getCartValue(items),
                    shipping_tier: shippingMethod ?? undefined,
                    coupon: this?.me?.cartInfo?.cart?.discountCodes
                        .map((it) => it.discountCode.code)
                        .join(", "),
                    items: items.map(this.mapLineItemToEventItem),
                },
            });
        } catch (e) {
            console.warn("AnalyticsClient.addShippingInfo error");
        }
    }

    addPaymentInfo(items: LineItem[], paymentType) {
        if (!items) {
            return;
        }

        try {
            this.saveEcommerceEvent({
                event: EventTypes.addPaymentInfo,
                ecommerce: {
                    value: this.getCartValue(items),
                    payment_type: paymentType ?? undefined,
                    coupon: this?.me?.cartInfo?.cart?.discountCodes
                        .map((it) => it.discountCode.code)
                        .join(", "),
                    items: items.map(this.mapLineItemToEventItem),
                },
            });
        } catch (e) {
            console.warn("AnalyticsClient.addPaymentInfo error");
        }
    }

    async purchaseAdded(order: Order) {
        try {
            this.saveEcommerceEvent({
                event: EventTypes.purchase,
                ecommerce: {
                    value: this.getCartValue(order.lineItems),
                    transaction_id: order?.orderNumber ?? undefined,
                    affiliation: "Online",
                    purchase_email: order.shippingAddress.email,
                    purchase_email_sha: await sha256(order.shippingAddress.email),
                    purchase_payment: order?.paymentInfo?.payments?.[0]?.paymentMethodInfo?.method,
                    purchase_store_id: order.store.key,
                    purchase_store_name: order.store.key,
                    shipping: order.shippingInfo.shippingMethodName,
                    tax: getMoneyFromCentsAndFractions(
                        order.taxedPrice.totalTax.centAmount,
                        order.taxedPrice.totalTax.fractionDigits
                    ),
                    event_id: await sha256(EventTypes.purchase + order?.orderNumber),
                    items: order.lineItems.map(this.mapLineItemToEventItem),
                },
            });

            this.saveEvent({
                event: AlgoliaEventTypes.checkoutProductPurchased,
                objectIDs: order.lineItems.map((item) => item.productKey),
                indexName: this.algoliaIndexName,
                methodName: "convertedObjectIDs",
                userToken:
                    this.me?.cartInfo?.cart?.anonymousId ??
                    this.me?.cartInfo?.cart?.id ??
                    "unknown_user",
            });
        } catch (e) {
            console.warn("AnalyticsClient.purchaseAdded error", e);
        }
    }

    filterView(filters) {
        if (!filters.length) {
            return;
        }
        try {
            const appliedFilters = filters.reduce((acc, item) => {
                const filter = `${item.label}: ${item.refinements
                    .map((it) => it.label)
                    .join(" OR ")}`;
                acc.push(filter);
                return acc;
            }, []);

            this.saveEvent({
                event: AlgoliaEventTypes.plpFilterViewed,
                indexName: this.algoliaIndexName,
                filters: appliedFilters,
                userToken:
                    this.me?.cartInfo?.cart?.anonymousId ??
                    this.me?.cartInfo?.cart?.id ??
                    "unknown_user",
            });
        } catch (e) {
            console.warn("AnalyticsClient.filterView error");
        }
    }

    listFilterApplied = (filterName, filterCategory) => {
        try {
            this.saveEvent({
                event: EventTypes.listFilterApplied,
                filter_category: filterCategory,
                filter_name: filterName,
            });
            this.lastAppliedFilter = { filterName, filterCategory };
        } catch (e) {
            console.warn("AnalyticsClient.listFilterApplied error");
        }
    };

    listSortingApplied = (sorting: string) => {
        try {
            this.saveEvent({
                event: EventTypes.listSortingApplied,
                sorting_applied: sorting,
            });
            this.lastAppliedSorting = { sorting };
        } catch (e) {
            console.warn("AnalyticsClient.listSortingApplied error");
        }
    };

    newsletterSubscribe = async (userEmail: string) => {
        try {
            this.saveEvent({
                event: EventTypes.newsletterSubscribe,
                nl_email: userEmail,
                nl_email_sha256: await sha256(userEmail),
                nl_first_name: undefined,
                nl_last_name: undefined,
            });
        } catch (e) {
            console.warn("AnalyticsClient.newsletterSubscribe error");
        }
    };

    purchaseStatus = (result, paymentMethod: string) => {
        try {
            this.saveEvent({
                event: EventTypes.purchaseStatus,
                status_order_id: result.orderId,
                status_pay_method: paymentMethod,
                status_category: this.resultCodeToPaymentState(result?.resultCode),
                status_response_code: result?.resultCode,
                status_reason: result.action,
            });
        } catch (e) {
            console.warn("AnalyticsClient.purchaseStatus error");
        }
    };

    accountCreate = () => {
        try {
            this.saveEvent({
                event: EventTypes.accountCreate,
            });
        } catch (e) {
            console.warn("AnalyticsClient.accountCreate error");
        }
    };

    cartContents = () => {
        if (!this.me?.cartInfo?.cart) {
            return;
        }
        this.saveEvent(
            {
                event: EventTypes.cartContents,
                basket_id: this.me?.cartInfo?.cart?.id,
                basket_url: window.location.hostname + "/shopping-bag",
                cart_size: this.me?.cartInfo?.cart?.lineItems.reduce((acc, it) => {
                    return it.quantity + acc;
                }, 0),
                currency: getCurrencyForSite,
                value: this.getCartValue(this.me?.cartInfo?.cart?.lineItems),
                items: this.me?.cartInfo?.cart?.lineItems.map(this.mapLineItemToEventItem),
            },
            false
        );
    };

    viewPromotion = ({
        image,
        title,
        index,
        id,
    }: {
        image: string;
        title: string;
        index: number;
        id: string;
    }) => {
        const url = image.startsWith("http") ? image : `https:${image}`;
        this.saveEcommerceEvent({
            event: EventTypes.viewPromotion,
            ecommerce: {
                promotion_id: id,
                promotion_name: title?.trim(),
                creative_slot: index,
                creative_name: url,
            },
        });
    };

    selectPromotion = ({
        image,
        title,
        index,
        id,
    }: {
        image: string;
        title: string;
        index: number;
        id: string;
    }) => {
        this.lastSelectedPromotion = {
            promotionId: id,
            promotionName: title.trim(),
        };

        this.saveEcommerceEvent({
            event: EventTypes.selectPromotion,
            ecommerce: {
                promotion_id: id,
                promotion_name: title.trim(),
                creative_slot: index,
                creative_name: `https:${image}`,
            },
        });
    };

    saveUserData = async (
        user: Me,
        session: Session,
        isAuthenticated: boolean,
        pageType: string
    ) => {
        try {
            const customerId = user?.customer?.id ?? user?.cartInfo?.cart?.anonymousId ?? "";

            const savedSuperUserId = JSON.parse(localStorage.getItem("superUserId"));

            const superId = await sha256(
                (customerId ?? "") + window.navigator.userAgent.toString()
            );

            const customerAddress =
                user?.customer?.defaultShippingAddress ??
                (user?.customer?.shippingAddresses && user?.customer?.shippingAddresses[0]) ??
                user?.cartInfo?.cart?.shippingAddress;
            const userDataEvent = {
                event: EventTypes.userData,
                user_id: isAuthenticated ? user?.customer?.id : session?.anonymousId,
                user_language: getLanguageForLocale(this.locale),
                super_user_id: savedSuperUserId ?? superId,
                custom_session_id: session?.accessToken,
                content_group: pageType ?? "generic",
                currency: this.currencyCode,
                visitor_gender: undefined,
                visitor_email: customerAddress?.email ?? undefined,
                visitor_phone: customerAddress?.phone ?? undefined,
                visitor_first_name: isAuthenticated ? user?.customer?.firstName : undefined,
                visitor_last_name: isAuthenticated ? user?.customer?.lastName : undefined,
                visitor_email_sha256: customerAddress?.email
                    ? await sha256(customerAddress?.email)
                    : undefined,
                visitor_postal_code: customerAddress?.postalCode ?? undefined,
                visitor_status: isAuthenticated ? "logged-in" : "guest",
                store_purchase: undefined,
                last_purchase: undefined,
                first_purchase: undefined,
                delivery_rate: undefined,
                wishlist_products: user.wishlistInfo?.wishlist?.lineItems?.length ?? 0,
                wishlist_value: user.wishlistInfo?.wishlist?.lineItems?.reduce((acc, it) => {
                    return (
                        acc +
                        getMoneyFromCentsAndFractions(
                            it?.variant?.price?.value?.centAmount,
                            it?.variant?.price?.value?.fractionDigits
                        )
                    );
                }, 0),
                visitor_type: user.customer?.createdAt ? "New User" : undefined,
            };

            user?.customer && sessionStorage.setItem("savedUser", JSON.stringify(user));
            !savedSuperUserId && localStorage.setItem("superUserId", JSON.stringify(superId));
            this.saveEcommerceEvent(userDataEvent);
        } catch (e) {
            console.warn("AnalyticsClient.saveUserData error", e);
        }
    };

    resultCodeToPaymentState = (resultCode: string) => {
        switch (resultCode) {
            case "Authorised":
                return PaymentState.Paid;
            case "Pending":
            case "Received":
            case "IdentifyShopper":
            case "ChallengeShopper":
                return PaymentState.Pending;
            case "Refused":
            case "Error":
                return PaymentState.Failed;
            default:
                return PaymentState.Failed;
        }
    };
}

const analyticsClient = new AnalyticsClient();

export { analyticsClient as AnalyticsClient };

export enum EventTypes {
    userData = "user_data",
    cartContents = "cart_contents",
    selectItem = "select_item",
    viewItem = "view_item",
    loadItemsList = "load_item_list",
    viewItemsList = "view_item_list",
    addToCart = "add_to_cart",
    addToWishlist = "add_to_wishlist",
    removeFromWishlist = "remove_from_wishlist",
    removeFromCart = "remove_from_cart",
    viewCart = "view_cart",
    beginCheckout = "begin_checkout",
    addShippingInfo = "add_shipping_info",
    addPaymentInfo = "add_payment_info",
    purchase = "purchase",
    newsletterSubscribe = "newsletter_subscribe",
    listFilterApplied = "list_filter_applied",
    listSortingApplied = "list_sorting_applied",
    purchaseStatus = "purchase_status",
    accountCreate = "account_create",
    viewPromotion = "view_promotion",
    selectPromotion = "select_promotion",
    pageView = "page_view",
}

enum AlgoliaEventTypes {
    plpFilterViewed = "PLP: Filter Viewed",
    checkoutProductPurchased = "Checkout: Product Purchased",
    pdpProductAddedAfterSearch = "PDP: Product Added to Cart after Search",
    pdpProductAddedToCart = "PDP: Product Added to Cart",
    pdpNavigatedWithoutSearch = "PDP: Navigated to Product without Search",
    pdpNavigatedAfterSearch = "PDP: Navigated to Product after Search",
}

type PossibleEventItem = Product | ProductVariant | LineItem;

type ListData = { listName?: string; listId?: string; position?: number; lastAppliedFilter?: any };

export enum AnalyticsListTypes {
    plp = "product_listing_page",
    search = "search",
    recommendations = "recommendations",
    recentlyViewed = "recently_viewed",
    custom = "custom",
}

type AnalyticDefaultItem = {
    item_id: string | number; // ObjectID
    item_variant: string; //sku
    item_name: string;
    promotion_name?: string;
    promotion_id?: string;
    item_list_name?: string;
    item_list_id?: string;
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_brand: string;
    currency: string;
    price: number;
    quantity: number;
    index: number;
    discount?: number;
    coupon: string;
    list_sorting?: string;
    list_filter_category?: string;
    list_filter_name?: string;
    list_search_key?: string;
    barcode: string;
};

type AnalyticsExtendedItem = AnalyticDefaultItem & {
    colour?: string;
    variant_price?: number;
    full_price?: number;
    default_variant?: string;
    size?: string;
    size_imperial?: string;
    size_type?: string;
    barcode?: string;
    stock_count_online?: number;
    stock_count_offline?: number;
    on_sale?: string;
    label_name?: string;
    label_source?: string;
    source?: string;
};

export type AlgoliaItem = {
    id: unknown;
    name: string;
    dominantColor: string;
    gender: string;
    factsAndBenefits: string[];
    price: number;
    priceRange: string;
    collectionClass: string;
    collectionSegment: string;
    hasStock: boolean;
    collectionType: string;
    division: string;
    sizes: string[];
    variants: { size: string; availableQuantity: number; sku: string }[];
    listPrice: number;
    materialGroup: string;
    productColors: { objectID: string }[];
    objectID: string;
    queryID: string;
    index: number;
    productName: string;
    articleNumber: string;
    colorCode: string;
    categories: string[];
    image: string;
    hasDiscount: boolean;
    listPriceRaw: Money;
    priceRaw: Money;
    discountPercentage: number;
    imageBackgroundColor: string;
    badges: string;
    descriptiveHeading: string;
};

import type {
    Cart,
    Customer,
    ProductData,
    ShippingMethod,
    ShoppingList,
} from "@graphql/generated/components";
import { produce } from "immer";
import type { DocumentNode } from "graphql";
import type { StoreApi } from "zustand";
import { createStore, useStore as useZustandStore } from "zustand";
import useIsomorphicLayoutEffect from "@ui/hooks/useIsomorphicLayoutEffect";
import type { RefinementListItem } from "instantsearch.js/es/connectors/refinement-list/connectRefinementList";
import { useContext, createContext, useRef } from "react";
import { Logger, LogTag, ServiceType } from "@lib/monitoring/logger";
import { persist } from "zustand/middleware";
import { merge } from "lodash-es";
import { SizeLocales } from "@lib/constants/filtersConstants";
import { shoeConventionForSite } from "@config/site/site-config";

export type Me = {
    customer?: Customer;
    globalShoeSizeConvention?: SizeLocales;
    cartInfo?: {
        cart?: Cart;
        masterProducts?: Map<string, ProductData>;
        shippingMethods?: ShippingMethod[];
        isExecuted: boolean;
    };
    wishlistInfo?: {
        wishlist?: ShoppingList;
        masterProducts?: Map<string, ProductData>;
        isExecuted: boolean;
    };
};

type customerCustomFields = {
    customer_CF_PhoneCountryCode?: string;
    customer_CF_PhoneNumber?: string;
    customer_CF_Gender?: string;
};

type Products = {
    list: any[];
    resultsCount: number | null;
    currentFilters: any[];
    initialFilters?: {
        [key: string]: Partial<RefinementListItem>[];
    };
    lastVisitedPlp?: string | null;
    algoliaQueryID?: string | string[] | null;
    lastLoadedPage: number;
    lastVisitedPlpContainerHeight?: number;
};

export type StoreDataType = {
    isLocked: boolean;
    hasFreshData: boolean;
    me: Me;
    payment: any;
    draftPromoCode?: string;
    products: Products;
    anonymousId?: string;
};

export type StoreState = {
    data: StoreDataType;
    setLocked: (isLocked: boolean) => void;
    setHasFreshData: (hasFreshData: boolean) => void;
    setGlobalShoeSizeConvention: (globalShoeSizeConvention: SizeLocales) => void;
    clearMe: () => void;
    setCustomer: (customer: Customer) => void;
    setCart: (cart: Cart) => void;
    clearCart: () => void;
    setWishlist: (wishlist: ShoppingList) => void;
    clearWishlist: () => void;
    setMasterProducts: (masterProducts: Map<string, ProductData>) => void;
    setWishlistMasterProducts: (masterProducts: Map<string, ProductData>) => void;
    setShippingMethods: (shippingMethods: ShippingMethod[]) => void;
    setRefreshToken: (refreshToken: string) => void;
    setPayment: (payment: any) => void;
    setProducts: (products: any[]) => void;
    setResultsCount: (number) => void;
    setCurrentFilters: (filters: any[]) => void;
    setInitialFilters: (initialFilters: { [key: string]: any[] }) => void;
    setLastVisitedPlp: (slug: string) => void;
    setLastLoadedPlpContainerHeight: (top: number) => void;
    setLastLoadedPage: (page: number) => void;
    setAlgoliaQueryID: (queryID: string) => void;
    setDraftPromoCode: (draftPromoCode: string) => void;
    setMyAddresses: (customer: Customer) => void;
    setMyProfile: (customer: Customer) => void;
    setActiveData: (cartAndWishlist: { cart: Cart; wishlist: ShoppingList }) => void;
    setWishlistIsExecuted: (isExecuted: boolean) => void;
    setAddToCartIsExecuted: (isExecuted: boolean) => void;
};

export const initialState: StoreDataType = {
    isLocked: false,
    hasFreshData: false,
    me: {
        customer: null,
        globalShoeSizeConvention: shoeConventionForSite?.[0] ?? SizeLocales.eu,
        cartInfo: {
            cart: null,
            masterProducts: null,
            shippingMethods: null,
            isExecuted: true,
        },
        wishlistInfo: { wishlist: null, masterProducts: null, isExecuted: true },
    },
    payment: null,
    draftPromoCode: null,
    products: {
        list: [],
        currentFilters: [],
        resultsCount: null,
        initialFilters: {},
        lastVisitedPlp: null,
        algoliaQueryID: null,
        lastLoadedPage: 1,
        lastVisitedPlpContainerHeight: 0,
    },
};

const maxRetries: number = 3;
const RETRY_ON_ERROR_CODES = ["502", "503", "504"];

export const initializeStore = (preloadedState = {}) => {
    return createStore<StoreState>()(
        persist(
            (set) => ({
                data: {
                    ...initialState,
                    ...preloadedState,
                },
                setLocked: (payload) =>
                    set(
                        produce((state) => {
                            state.isLocked = payload;
                        })
                    ),
                setRefreshToken: (payload) =>
                    set(
                        produce((state) => {
                            state.data.refreshToken = payload;
                        })
                    ),
                setActiveData: (cartAndWishlist) =>
                    set(
                        produce((state) => {
                            state.data.me.cartInfo.cart = cartAndWishlist.cart;
                            state.data.me.wishlistInfo.wishlist = cartAndWishlist.wishlist;
                        })
                    ),
                clearMe: () =>
                    set(
                        produce((state) => {
                            state.data.me = initialState.me;
                        })
                    ),
                setPayment: (payload) =>
                    set(
                        produce((state) => {
                            state.data.payment = payload;
                        })
                    ),
                setCart: (cart) =>
                    set(
                        produce((state) => {
                            state.data.me.cartInfo.cart = cart;
                        })
                    ),
                clearCart: () =>
                    set(
                        produce((state) => {
                            state.data.me.cartInfo.cart = null;
                        })
                    ),
                setWishlist: (wishlist) =>
                    set(
                        produce((state) => {
                            state.data.me.wishlistInfo.wishlist = wishlist;
                        })
                    ),
                setGlobalShoeSizeConvention: (globalShoeSizeConvention) =>
                    set(
                        produce((state) => {
                            state.data.me.globalShoeSizeConvention = globalShoeSizeConvention;
                        })
                    ),
                clearWishlist: () =>
                    set(
                        produce((state) => {
                            state.data.me.wishlistInfo.wishlist = null;
                            state.data.me.wishlistInfo.masterProducts = null;
                        })
                    ),
                setWishlistIsExecuted: (isExecuted) =>
                    set(
                        produce((state) => {
                            state.data.me.wishlistInfo.isExecuted = isExecuted;
                        })
                    ),
                setAddToCartIsExecuted: (isExecuted) =>
                    set(
                        produce((state) => {
                            state.data.me.cartInfo.isExecuted = isExecuted;
                        })
                    ),
                setMasterProducts: (masterProducts) =>
                    set(
                        produce((state) => {
                            state.data.me.cartInfo.masterProducts = masterProducts;
                        })
                    ),
                setWishlistMasterProducts: (masterProducts) =>
                    set(
                        produce((state) => {
                            state.data.me.wishlistInfo.masterProducts = masterProducts;
                        })
                    ),
                setShippingMethods: (shippingMethods) =>
                    set(
                        produce((state) => {
                            state.data.me.cartInfo.shippingMethods = shippingMethods;
                        })
                    ),
                setProducts: (payload) =>
                    set(
                        produce((state) => {
                            state.data.products.list = payload;
                        })
                    ),
                setResultsCount: (payload) =>
                    set(
                        produce((state) => {
                            state.data.products.resultsCount = payload;
                        })
                    ),
                setCurrentFilters: (payload) =>
                    set(
                        produce((state) => {
                            state.data.products.currentFilters = payload;
                        })
                    ),
                setLastLoadedPage: (payload) =>
                    set(
                        produce((state) => {
                            state.data.products.lastLoadedPage = payload;
                        })
                    ),
                setLastLoadedPlpContainerHeight: (payload) =>
                    set(
                        produce((state) => {
                            state.data.products.lastVisitedPlpContainerHeight = payload;
                        })
                    ),
                setInitialFilters: (payload: { color: any; size: any; category: any }) =>
                    set(
                        produce((state) => {
                            state.data.products.initialFilters = payload;
                        })
                    ),
                setLastVisitedPlp: (payload: string) =>
                    set(
                        produce((state) => {
                            state.data.products.lastVisitedPlp = payload;
                        })
                    ),
                setAlgoliaQueryID: (payload: string) =>
                    set(
                        produce((state) => {
                            state.data.products.algoliaQueryID = payload;
                        })
                    ),
                setDraftPromoCode: (draftPromoCode: string) =>
                    set(
                        produce((state) => {
                            state.data.draftPromoCode = draftPromoCode;
                        })
                    ),
                setHasFreshData: (hasFreshData: boolean) =>
                    set(
                        produce((state) => {
                            state.data.hasFreshData = hasFreshData;
                        })
                    ),
                setCustomer: (customer: Customer) =>
                    set(
                        produce((state) => {
                            state.data.me.customer = customer;
                        })
                    ),
                setCustomerVersion: (customer: Customer) =>
                    set(
                        produce((state) => {
                            if (!state.data.me.customer) state.data.me.customer = {};
                            const { id, version } = state.data.me.customer;
                            if (id !== customer.id) {
                                state.data.me.customer = customer;
                                return;
                            }
                            if (version > customer.version) {
                                return;
                            }
                            state.data.me.customer.version = customer.version;
                        })
                    ),
                setMyAddresses: (customer: Customer) =>
                    set(
                        produce((state) => {
                            if (!state.data.me.customer) state.data.me.customer = {};
                            const { id, version, addresses, defaultShippingAddressId } =
                                state.data.me.customer;
                            if (id !== customer.id) {
                                state.data.me.customer.id = customer.id;
                                state.data.me.customer.version = customer.version;
                                state.data.me.customer.addresses = customer.addresses;
                                state.data.me.customer.defaultShippingAddressId =
                                    customer.defaultShippingAddressId;
                                return;
                            }
                            if (!version) {
                                state.data.me.customer.version = customer.version;
                            }
                            if (version < customer.version) {
                                // if equal no need to set, if greater better not set
                                state.data.me.customer.version = customer.version;
                            }

                            if (JSON.stringify(addresses) !== JSON.stringify(customer.addresses)) {
                                state.data.me.customer.addresses = customer.addresses;
                                state.data.me.customer.defaultShippingAddressId =
                                    customer.defaultShippingAddressId;
                                return;
                            }
                            if (!defaultShippingAddressId) {
                                return;
                            }
                            if (defaultShippingAddressId !== customer?.defaultShippingAddressId) {
                                state.data.me.customer.defaultShippingAddressId =
                                    customer.defaultShippingAddressId;
                            }
                        })
                    ),
                setMyProfile: (customer: Customer) => {
                    return set(
                        produce((state) => {
                            if (!state.data.me.customer) state.data.me.customer = {};
                            const customFields = {} as customerCustomFields;
                            customer.custom?.customFieldsRaw?.forEach((field) => {
                                customFields[field.name] = field.value;
                            });
                            state.data.me.customer.countryCode =
                                customFields.customer_CF_PhoneCountryCode
                                    ? customFields.customer_CF_PhoneCountryCode
                                    : "";
                            state.data.me.customer.phoneNumber =
                                customFields.customer_CF_PhoneNumber
                                    ? customFields.customer_CF_PhoneNumber
                                    : "";
                            state.data.me.customer.gender = customFields.customer_CF_Gender || "";
                            state.data.me.customer.email = customer.email;
                            if (customer.id && customer.id !== state.data.me.customer.id) {
                                state.data.me.customer.id = customer.id;
                                state.data.me.customer.version = customer.version;
                            } else {
                                //it's the same customer let's figure out what the version state potentially is
                                if (!state.data.me.customer.version) {
                                    state.data.me.customer.version = customer.version;
                                }
                                if (state.data.me.customer.version < customer.version) {
                                    // if equal no need to set, if greater better not set
                                    state.data.me.customer.version = customer.version;
                                }
                            }

                            state.data.me.customer.dateOfBirth = customer.dateOfBirth;
                            state.data.me.customer.firstName = customer.firstName;
                            state.data.me.customer.lastName = customer.lastName;
                        })
                    );
                },
            }),
            {
                partialize: (state) => ({
                    data: {
                        me: {
                            globalShoeSizeConvention: state.data.me.globalShoeSizeConvention,
                        },
                    },
                }),
                name: "globalShoeSizeConvention",
                merge: (persistedState: Partial<StoreState>, currentState) => {
                    if (
                        !shoeConventionForSite.includes(
                            persistedState.data.me.globalShoeSizeConvention
                        )
                    ) {
                        return merge(currentState, {
                            data: {
                                me: {
                                    globalShoeSizeConvention: shoeConventionForSite[0],
                                },
                            },
                        });
                    } else {
                        return merge(currentState, persistedState);
                    }
                },
            }
        )
    );
};

const zustandContext = createContext<StoreApi<StoreState>>(initializeStore(initialState));

export const ZustandProvider = ({ children, initialState = {} }) => {
    const storeRef = useRef<StoreApi<StoreState>>();
    if (!storeRef.current) {
        storeRef.current = initializeStore(initialState);
    }

    return <zustandContext.Provider value={storeRef.current}>{children}</zustandContext.Provider>;
};
export function useStore<T>(selector?: (state: StoreState) => T) {
    const store = useContext(zustandContext);

    if (!store) throw new Error("Store is missing the provider");

    const sel: (st) => T | StoreState = selector ?? ((state: StoreState) => state);

    return useZustandStore(store, sel);
}

export const useStoreApi = () => {
    return useContext(zustandContext);
};

export function useCreateStore(initialState: { data: StoreDataType }) {
    // For CSR, always re-use same store.
    const store = initializeStore(initialState);
    // And if initialState changes, then merge states in the next render cycle.
    //
    // eslint complaining "React Hooks must be called in the exact same order in every component render"
    // is ignorable as this code runs in same order in a given environment
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useIsomorphicLayoutEffect(() => {
        if (initialState && store) {
            store.setState({
                ...store.getState(),
                ...initialState,
            });
        }
    }, [initialState]);

    return () => store;
}

export function updateMutation<T>(execute, fn) {
    const traceID = Math.random().toString(36).substring(7);
    const executeWithRetry = async (variables: T | null, retryCount = 0) => {
        const { error, data } = await execute(variables);
        const mutationName = error
            ? error.graphQLErrors?.[0]?.path?.[0]
            : Object.keys(data || {})[0];
        const logPayload = {
            variables,
            execute,
            mutationName,
            retry: retryCount,
            error,
            service: "retry-" + (process.env.NEXT_PUBLIC_HOSTNAME || ""),
            requestHash: traceID,
        };

        const logMessage = `GraphQL Mutation ${mutationName}`;

        if (
            error &&
            RETRY_ON_ERROR_CODES.some((code) => error.message.includes(code)) &&
            retryCount < maxRetries
        ) {
            Logger.error(ServiceType.COMMERCE_TOOLS, logMessage, {
                tag: LogTag.ERROR,
                ...logPayload,
            });
            return executeWithRetry(variables, retryCount + 1);
        } else {
            if (error) {
                Logger.error(ServiceType.COMMERCE_TOOLS, logMessage, {
                    tag: LogTag.ERROR,
                    ...logPayload,
                });
            } else {
                Logger.info(ServiceType.COMMERCE_TOOLS, logMessage, {
                    tag: LogTag.SUCCESS,
                    ...logPayload,
                });
            }
        }

        // Testing log missing address fields
        if (!error) {
            if (mutationName === "updateMyCart") {
                const allAddressFields = [
                    "streetName",
                    "postalCode",
                    "city",
                    "region",
                    "phone",
                    "firstName",
                    "lastName",
                ];
                const missingFieldsBefore = allAddressFields.filter(
                    (field) => !variables.hasOwnProperty(field)
                );
                const missingFieldsAfter = allAddressFields.filter(
                    (field) => !data.updateMyCart.shippingAddress.hasOwnProperty(field)
                );

                if (missingFieldsBefore.length || missingFieldsAfter.length) {
                    // Send logs to datadog with error and tag as "missing-address-fields"
                    Logger.error(
                        ServiceType.COMMERCE_TOOLS,
                        "Missing shipping address attributes",
                        {
                            ...logPayload,
                            missingFieldsBefore,
                            missingFieldsAfter,
                            tag: LogTag.MISSING_ADDRESS_FIELDS,
                            cartId: data?.updateMyCart?.id,
                            productKeys: data?.updateMyCart?.lineItems?.map(
                                (item) => item.productKey
                            ),
                        }
                    );
                }
            }
            fn(data[mutationName]);
        }
        return { error, data };
    };
    return (variables: T | null) => executeWithRetry(variables);
}

export function updateQuery<T>(execute, fn, selector, document: DocumentNode, filter: any) {
    return async function executor(variables: any) {
        const injectedFilter = filter;
        Object.entries(variables).forEach(([vKey, vValue]) => {
            const token = `$${vKey}`;
            Object.entries(injectedFilter).forEach(([fKey, fValue]) => {
                if ((fValue as string).indexOf(token) !== -1) {
                    if (Array.isArray(vValue)) injectedFilter[fKey] = vValue;
                    else injectedFilter[fKey] = injectedFilter[fKey].replace(token, vValue);
                }
            });
        });

        const { error, data } = await execute(document, injectedFilter).toPromise();

        if (!error) {
            fn(selector(data));
        }
        return { error, data };
    };
}

/**
 * Will set isLocked on store.data.isLocked and run a wrapped function.
 *
 * This can be used for disabling buttons and whatever else will manipulate the current cart.
 * We do this to avoid cart version mismatch, which will happen if multiple operations
 * are executed on the same cart version.
 *
 * Subscribers can listen to store.data.isLocked
 */
export const useLock = () => {
    const api = useStoreApi();

    const unlock = () => {
        api.setState((state) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    isLocked: false,
                },
            };
        });
    };

    return (fn: (...args: any[]) => any): any => {
        return async (...args: any) => {
            // We set the isLocked state outside the React render cycle,
            // so we don't use setLocked.
            api.setState((state) => {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        isLocked: true,
                    },
                };
            });

            try {
                const result = await fn(...args);
                unlock();
                return result;
            } catch (error) {
                unlock();
                throw error;
            }
        };
    };
};

import {
    CAOrderNumber,
    EEOrderNumber,
    ESOrderNumber,
    INOrderNumber,
    KROrderNumber,
    MYOrderNumber,
    SGOrderNumber,
    USOrderNumber,
} from "@lib/validators/regex";

export const shoeSizingConventions = ["EU", "US"];

export const localizationConfig = {
    "my.ecco.com": {
        country: "MY",
        countryName: "Malaysia",
        language: "en",
        currency: "MYR",
        fractionDigits: 2,
        shoeSizingConventions: ["EU", "US"],
        checkout: {
            countryCode: "+60",
        },
        // Get geo data from https://github.com/mihai-craita/countries_center_box/blob/master/countries.json
        geo: {
            centerLat: 4.210484,
            centerLng: 101.975766,
            swLat: 0.853821,
            swLng: 99.640573,
            neLat: 7.363468,
            neLng: 119.269362,
            // initial zoom level defined by UX team
            initZoomLevel: 6,
        },
        orderNumberFormatRegex: MYOrderNumber,
        reCaptchaIntegration: {
            contactForm: false,
            checkout: false,
        },
    },
    "sg.ecco.com": {
        country: "SG",
        countryName: "Singapore",
        language: "en",
        currency: "SGD",
        fractionDigits: 2,
        shoeSizingConventions: ["EU", "US"],
        checkout: {
            countryCode: "+65",
        },
        geo: {
            centerLat: 1.352083,
            centerLng: 103.819836,
            swLat: 1.166398,
            swLng: 103.605575,
            neLat: 1.470772,
            neLng: 104.085557,
            initZoomLevel: 11,
        },
        orderNumberFormatRegex: SGOrderNumber,
        reCaptchaIntegration: {
            contactForm: false,
            checkout: false,
        },
    },
    "es.ecco.com": {
        country: "ES",
        countryName: "Spain",
        language: "es",
        currency: "EUR",
        fractionDigits: 2,
        shoeSizingConventions: ["EU", "US"],
        checkout: {
            countryCode: "+34",
        },
        geo: {
            centerLat: 40.463667,
            centerLng: -3.74922,
            swLat: 27.637789,
            swLng: -18.160788,
            neLat: 43.79238,
            neLng: 4.327784,
            initZoomLevel: 7,
        },
        orderNumberFormatRegex: ESOrderNumber,
        reCaptchaIntegration: {
            contactForm: false,
            checkout: false,
        },
    },
    "ee.ecco.com": {
        country: "EE",
        countryName: "Estonian",
        language: "et",
        currency: "EUR",
        fractionDigits: 2,
        shoeSizingConventions: ["EU", "US"],
        checkout: {
            countryCode: "+372",
        },
        geo: {
            centerLat: 58.595272,
            centerLng: 25.013607,
            swLat: 57.509316,
            swLng: 21.764313,
            neLat: 59.685685,
            neLng: 28.210139,
            initZoomLevel: 8,
        },
        orderNumberFormatRegex: EEOrderNumber,
        reCaptchaIntegration: {
            contactForm: false,
            checkout: false,
        },
    },
    "in.ecco.com": {
        country: "IN",
        countryName: "India",
        language: "en",
        currency: "INR",
        fractionDigits: 2,
        shoeSizingConventions: ["UK", "EU"],
        checkout: {
            countryCode: "+91",
        },
        geo: {
            centerLat: 20.593684,
            centerLng: 78.96288,
            swLat: 6.753516,
            swLng: 68.162386,
            neLat: 35.504475,
            neLng: 97.395555,
            initZoomLevel: 6,
        },
        orderNumberFormatRegex: INOrderNumber,
        reCaptchaIntegration: {
            contactForm: true,
            checkout: false,
        },
    },
    "kr.ecco.com": {
        country: "KR",
        countryName: "South Korea",
        language: "ko",
        currency: "KRW",
        shoeSizingConventions: ["KR", "EU"],
        fractionDigits: 0,
        checkout: {
            countryCode: "+82",
        },
        geo: {
            centerLat: 35.907757,
            centerLng: 127.766922,
            swLat: 33.10611,
            swLng: 124.608139,
            neLat: 38.616931,
            neLng: 130.923218,
            initZoomLevel: 8,
        },
        orderNumberFormatRegex: KROrderNumber,
        reCaptchaIntegration: {
            contactForm: false,
            checkout: false,
        },
    },
    "ca.ecco.com": {
        country: "CA",
        countryName: "Canada",
        language: "en",
        currency: "CAD",
        shoeSizingConventions: ["CA", "EU"],
        fractionDigits: 2,
        checkout: {
            countryCode: "+1",
        },
        geo: {
            centerLat: 56.130366,
            centerLng: -106.346771,
            swLat: 41.676556,
            swLng: -141.00187,
            neLat: 83.115061,
            neLng: -52.619409,
            initZoomLevel: 4,
        },
        orderNumberFormatRegex: CAOrderNumber,
        reCaptchaIntegration: {
            contactForm: true,
            checkout: true,
        },
    },
    "us.ecco.com": {
        country: "US",
        countryName: "United States",
        language: "en",
        currency: "USD",
        shoeSizingConventions: ["US", "EU"],
        fractionDigits: 2,
        checkout: {
            countryCode: "+1",
        },
        geo: {
            centerLat: 39.50024,
            centerLng: -98.350891,
            swLat: 25.820898,
            swLng: -124.712891,
            neLat: 49.38024,
            neLng: -66.940662,
            initZoomLevel: 5,
        },
        orderNumberFormatRegex: USOrderNumber,
        reCaptchaIntegration: {
            contactForm: true,
            checkout: true,
        },
    },
};

import type {
    AddAddressToCustomerMutationVariables,
    AddDefaultShippingAddressToCustomerMutationVariables,
    GetMyAddressesQuery,
    GetMyProfileQuery,
    RemoveAddressByIdMutationVariables,
    SetDefaultShippingAddressByIdMutationVariables,
    UpdateAddressToCustomerMutationVariables,
    UpdateDefaultShippingAddressToCustomerMutationVariables,
} from "@graphql/generated/components";
import {
    GetMyAddressesDocument,
    GetMyProfileDocument,
    useAddAddressToCustomerMutation,
    useAddDefaultShippingAddressToCustomerMutation,
    useRemoveAddressByIdMutation,
    useSetDefaultShippingAddressByIdMutation,
    useUpdateAddressToCustomerMutation,
    useUpdateDefaultShippingAddressToCustomerMutation,
} from "@graphql/generated/components";
import { useClient } from "urql";
import { updateMutation, updateQuery, useStore } from ".";

export const useGetMyAddresses = () => {
    const client = useClient();
    const setData = useStore((state) => state.setMyAddresses);

    const selector = (data) => {
        return data?.me?.customer;
    };

    return updateQuery<GetMyAddressesQuery>(
        client.query,
        setData,
        selector,
        GetMyAddressesDocument,
        {}
    );
};
export const useGetMyProfile = () => {
    const client = useClient();
    const setData = useStore((state) => state.setMyProfile);

    const selector = (data) => {
        return data?.me?.customer;
    };
    return updateQuery<GetMyProfileQuery>(
        client.query,
        setData,
        selector,
        GetMyProfileDocument,
        {}
    );
};

export const useUpdateAddressToCustomer = () => {
    const [_, execute] = useUpdateAddressToCustomerMutation();
    const setData = useStore((state) => state.setMyAddresses);
    return updateMutation<UpdateAddressToCustomerMutationVariables>(execute, setData);
};

export const useUpdateShippingAddressToCustomer = () => {
    const [_, execute] = useUpdateDefaultShippingAddressToCustomerMutation();
    const setData = useStore((state) => state.setMyAddresses);
    return updateMutation<UpdateDefaultShippingAddressToCustomerMutationVariables>(
        execute,
        setData
    );
};

export const useAddAddressToCustomer = () => {
    const [_, execute] = useAddAddressToCustomerMutation();
    const setData = useStore((state) => state.setMyAddresses);
    return updateMutation<AddAddressToCustomerMutationVariables>(execute, setData);
};

export const useAddShippingAddressToCustomer = () => {
    const [_, execute] = useAddDefaultShippingAddressToCustomerMutation();
    const setData = useStore((state) => state.setMyAddresses);
    return updateMutation<AddDefaultShippingAddressToCustomerMutationVariables>(execute, setData);
};

export const useRemoveAddressToCustomer = () => {
    const [_, execute] = useRemoveAddressByIdMutation();
    const setData = useStore((state) => state.setMyAddresses);
    return updateMutation<RemoveAddressByIdMutationVariables>(execute, setData);
};

export const useSetDefaultShippingAddressById = () => {
    const [_, execute] = useSetDefaultShippingAddressByIdMutation();
    const setData = useStore((state) => state.setMyAddresses);
    return updateMutation<SetDefaultShippingAddressByIdMutationVariables>(execute, setData);
};
